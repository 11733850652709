import { h } from 'preact';
import { Router } from 'preact-router';
import Demo1 from '../routes/demo1'
import Demo2 from '../routes/demo2'
import Redirect from '../routes/Redirect';

const App = () => { 
	return (
		<div id="app">
			<Router>
				<Demo1 path="/v1"/>
				<Demo2 path="/v2"/>
				<Redirect path="/" to="/v1"/>
			</Router>
		</div>
	)
}

export default App;
